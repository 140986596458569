<template>
  <div id="masterWallet">
    <BottomNav />
    <van-nav-bar title="钱包" :border="false" left-arrow @click-left="goLastPage">
    </van-nav-bar>
    <div class="wallet-top-bac">
      <div class="balance">
        <p class="moneyType">当前余额(元)</p>
        <p class="moneyNum">{{wallet_info.balance?wallet_info.balance:"0.00"}}</p>
        <!-- <div class="plug-in" @click="goSubPage('withdraw-bill')">
          <span class="flexCenter">收支明细</span>
        </div> -->
        <div class="withdrawWrap">
          <div class="withdraw flexCenter" @click="goWithdrawPage">
            <svg class="icon icon-reset-wallet" aria-hidden="true">
              <use xlink:href="#icon-wodedingdan1"></use>
            </svg>
            <span>提现</span>
          </div>
          <div class="withdrawList flexCenter" @click="goSubPage('withdraw-bill')">
            <svg class="icon icon-reset-wallet" aria-hidden="true">
              <use xlink:href="#icon-dingdan-"></use>
            </svg>
            <span>提现记录</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BottomNav from "@/components/NavBar/BottomNav.vue";
export default {
  components: {
    BottomNav,
  },
  data() {
    return {
      wallet_info: {},
      verified_info: {},

      balance: "0.00",
      subListArr: [
        {
          icon: "icon-hongbaoB",
          title: "师傅红包",
          tip: "暂无点不动",
        },
        {
          id: 1,
          icon: "icon-icon_baozhengjinguanli",
          title: "诚信保证金",
          tip: "0元",
          routerName: "security_deposit",
        },
        // {
        //   icon: "&#xe675;",
        //   title: "百万合伙人",
        //   tip: "邀请入驻有奖",
        // },
        {
          id: 2,
          icon: "icon-yinhangka2",
          title: "银行卡",
          tip: "暂无绑定银行卡",
          routerName: "bank-card",
        },
        // {
        //   id: 3,
        //   icon: "icon-record-01",
        //   title: "提现记录",
        //   tip: "提现流水明细",
        // },
        // {
        //   icon: "&#xe675;",
        //   title: "商城",
        //   tip: "优惠多·快点购",
        // },
        // {
        //   icon: "&#xe675;",
        //   title: "师傅保障",
        //   tip: "意外险·意外保",
        // },
      ],
      verified_obj: [],
    };
  },

  mounted() {},
  created() {
    this.getProfileInfo();
    this.getWalletInfo();
  },
  methods: {
    getProfileInfo() {
      this.$http.get("api/v2/worker/profile/last_id_card").then((res) => {
        console.log(res);
        this.verified_info = res.data.id_card;
      });
    },
    getWalletInfo() {
      this.$http.get("/api/v2/worker/wallet/balance").then((res) => {
        if (res.data.result) {
          console.log("获取个人钱包余额", res);
          this.wallet_info = res.data.result;
        }
      });
    },
    goWithdrawPage() {
      /**
       * 在此请求实名认证
       */
      // 0 待审核 1 通过 2 未通过 - 1 撤销审核
      if (this.verified_info) {
        switch (this.verified_info.status) {
          case 0:
            this.$dialog.confirm({
              title: "提现",
              message: "实名认证审核中",
            });
            break;
          case 1:
            this.$router.push({
              name: "withdraw",
            });
            break;
          case 2:
            this.$dialog
              .confirm({
                title: "提现",
                message: "实名认证未通过",
                confirmButtonText: "重新认证",
              })
              .then(() => {
                this.$router.push({
                  name: "certification",
                });
              })
              .catch(() => {});
            break;
          case -1:
            this.$dialog
              .confirm({
                title: "提现",
                message: "您已撤销审核，请重新认证",
                confirmButtonText: "重新认证",
              })
              .then(() => {
                this.$router.push({
                  name: "certification",
                });
              })
              .catch(() => {});
            break;
        }
      } else {
        this.$dialog
          .confirm({
            title: "提现",
            message: "为保障钱包安全，请实名认证",
            confirmButtonText: "去实名认证",
          })
          .then(() => {
            this.$router.push({
              name: "certification",
            });
          })
          .catch(() => {});
      }

      // if(res.data)

      // this.$router.push({
      //   name: "withdraw",
      // });
    },
  },
};
</script>
<style>
</style>
<style lang="less">
#masterWallet {
  .van-dialog__header {
    font-size: 2.8rem;
  }
  .wallet-top-bac {
    width: 100%;
    height: 25rem;
    background: linear-gradient(to bottom, #ff7963, #ff816c00);
    box-sizing: border-box;
  }
  .icon-reset-wallet {
    width: 2.5rem;
    height: 2.5rem;
  }
  padding-bottom: 10rem;
  .balance {
    width: 94%;
    padding: 2.5rem 0;
    background: #fff;
    margin: 45px auto;
    border-radius: 20px;
    position: absolute;
    left: calc(50% - 47%);
    overflow: hidden;
    height: 16rem;
    p {
      padding: 0.3rem;
      text-align: center;
      &.moneyType {
        font-size: 1.4rem;
        color: @themeFontColor;
      }
      &.moneyNum {
        font-size: 5rem;
        font-weight: 600;
      }
    }
    .plug-in {
      position: absolute;
      width: 8rem;
      background: #ff7e7999;
      border-radius: 100px;
      top: 2.5rem;
      right: -0.8rem;
      padding: 0.4rem 0.8rem;
      font-size: 1.3rem;
      color: #fff;
    }
    .withdrawWrap {
      margin-top: 2rem;
      display: flex;
      justify-content: space-around;
      div {
        width: 50%;
        font-size: 1.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &.withdraw {
          border-right: 0.2px solid #efeff3;
          span {
            padding-top: 0.5rem;
          }
        }
        &.withdrawList {
          border-left: 0.2px solid #efeff3;
          span {
            padding-top: 0.5rem;
          }
        }
      }
    }
  }
  .subList {
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    transform: translateY(5rem);
    .subItem {
      font-size: 1.35rem;
      width: 30%;
      margin: 1.2rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #fff;
      padding: 1rem 0.2rem;
      border-radius: 10px;
      span {
        padding: 0.2rem 0;
        &.main {
          font-size: 1.4rem;
          padding: 0.5rem 0;
        }
        &.main2 {
          font-size: 1.2rem;
          color: @themeFontColor;
        }
      }
    }
  }
}
</style>